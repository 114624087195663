import classNames from "classnames";
import styles from "./TipInfos.module.scss";

function TipInfos({ title, images, resume, content, list, className, videoUrl, date }) {
  return (
    <div className={classNames(styles.tipInfos, className)}>
      {resume && (
        <div
          className={styles.infosResume}
          dangerouslySetInnerHTML={{ __html: resume }}
        />
      )}
      <div  className={styles.line}  />
      {
        videoUrl ? 
        (
          <iframe className={styles.infosImage} src={videoUrl} title="Como REUTILIZAR ÁGUA na lavadora de alta pressão" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        )
        :
        (
          <img
            className={styles.infosImage}
            alt={title} 
            src={`/tips/${images}`}
          />
        )
      }
      <div
        className={styles.infosDescription}
        dangerouslySetInnerHTML={{ __html: content }}
      />

      {list && (
        <div 
          className={styles.infosList}
          dangerouslySetInnerHTML={{ __html: list }}
        />
      )}

     
    </div>
    
  );
}

export default TipInfos;
