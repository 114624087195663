import classNames from "classnames";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import useAppContext from "../../../providers/App";

import VideoItem from "../../molecule/VideoItem";
import Button from "../../atom/Button";

import styles from "./Videos.module.scss";

function Videos({ videos = [], startItem = 0 }) {
  const { isPhone, isTablet, isDesktop, screenSize } = useAppContext();

  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'clique',
      'eventLabel': 'ver_todos_os_vídeos',
      'eventValue': ''
    });
  };

  const arrow = (clickHandler, hasNext, type = "prev") => {
    if (!hasNext) return;
    return (
      <button
        onClick={clickHandler}
        className={classNames(
          styles.arrows,
          type === "prev" ? styles.arrowLeft : styles.arrowRight
        )}
      >
        <FontAwesomeIcon icon={type === "prev" ? faAngleLeft : faAngleRight} />
      </button>
    );
  };

  const handleSlidesToShow = () => {
    if (screenSize <= 580) return 100;
    if (isDesktop) return 33.33;
    if (isTablet) return 50;
    if (isPhone) return 60;
  };

  return (
    <section className={styles.videos}>
      <h3>ASSISTA AOS VÍDEOS E CONHEÇA MELHOR OS PRODUTOS</h3>
      <div className={classNames("container", styles.container)}>
        <Carousel
          selectedItem={startItem}
          className={styles.slider}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop={true}
          centerMode={true}
          centerSlidePercentage={handleSlidesToShow()}
          renderArrowPrev={(clickHandler, hasPrev) =>
            arrow(clickHandler, hasPrev, "prev")
          }
          renderArrowNext={(clickHandler, hasNext) =>
            arrow(clickHandler, hasNext, "next")
          }
        >
          {videos.map((video, index) => (
            <VideoItem
              {...video}
              key={`item-${index}`}
              className={styles.videoItem}
              onClick={handleClick}
            />
          ))}
        </Carousel>
        <div className={styles.seeAll}>
          <Button

            to="/videos"
            theme="secondary"
            size={!isDesktop ? "sm" : "md"}
          >
            VEJA TODOS OS VÍDEOS
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Videos;
