import classNames from "classnames";

import styles from "./HeaderSquareItems.module.scss";

function HeaderSquareItems({
  type = "primary",
  background,
  backgroundColor,
  title,
  subtitle,
  className = "",
}) {
  return (
    <div
      className={classNames(
        styles.headerSquareItems,
        { [styles.primary]: type === "primary" },
        { [styles.secondary]: type === "secondary" },
        { [styles.tertiary]: type === "tertiary" },
        className
      )}
      style={{ backgroundImage: `url(${background})`, backgroundColor }}
    >
      <div className="container">
        <div className={styles.infos}>
          {title && <h3>{title}</h3>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
    </div>
  );
}

export default HeaderSquareItems;
