import axios from "axios";

const apiUrl = process.env.REACT_APP_ENVIRONMENT === 'dev'
  ? 'https://contact.free.beeceptor.com'
  : 'https://lavadoras.stihl.com.br/api/';

let instance = axios.create({
  baseURL: apiUrl,
  responseType: "json"
});

export default instance;