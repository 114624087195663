import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import Button from "../../atom/Button";

import styles from "./StoreItem.module.scss";

function StoreItem({
  name,
  state,
  city,
  district,
  postalCode,
  street,
  number,
  complement,
  phone,
  email,
  className = "",
}) {
  const handleSeeOnMap = () => {
    window.open('https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(`${name}, ${street}, ${number}, ${city}, ${state}, ${postalCode}`), '_blank');
  };
  return (
    <div className={classNames(styles.storeItem, className)}>
      <strong className={styles.title}>{name}</strong>
      <div className={styles.infos}>
        <p>
          {street}
          {number ? `, ${number}` : ""}
          {complement ? `, ${complement}` : ""}
          {postalCode ? ` - ${postalCode}` : ""}
        </p>
        <p>
          {district ? `${district} - ` : ""}
          {city} / {state}
        </p>
        {phone && <p>{phone}</p>}
      </div>
      <Button onClick={handleSeeOnMap} type="link" className={styles.button}>
        <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
        <span>VER NO MAPA</span>
      </Button>
    </div>
  );
}

export default StoreItem;
