import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import Flag from "../../atom/Flag";
import Button from "../../atom/Button";

import styles from "./ProductInfos.module.scss";
// code,
function ProductInfos({ title, isRelease,  description, className }) {
  return (
    <div className={classNames(styles.productInfos, className)}>
      {isRelease && <Flag label="LANÇAMENTO" className={styles.infosFlag} />}
      <h3 className={styles.infosTitle}>{title}</h3>
      {/* {code && <p className={styles.infosCode}>CÓD.: {code}</p>} */}
      {description && (
        <p
          className={styles.infosDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      
      )}
      <span>
        Segurança do produto Lavadora de alta pressão {title} certificada compulsoriamente junto ao INMETRO.
      </span>
      <Button to="/onde-encontrar" className={styles.infosButton}>
        <div className={styles.infosIcon}>
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
        ONDE ENCONTRAR
      </Button>
    </div>
  );
}

export default ProductInfos;
