import styles from "./AttributeBox.module.scss";

function AttributeBox({ id, title, description, image, onClick }) {
  return (
    <div onClick={onClick} className={styles.attributeBox}>
      <div className={styles.image}>
        <img alt={title} src={`/attributes/${image}`} />
      </div>
      <strong className={styles.title}>{title}</strong>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

export default AttributeBox;
