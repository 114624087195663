import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import styles from "./MenuButton.module.scss";

function MenuButton({ label = "Menu", onClick, isMenuOpen, className }) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        styles.menuButton,
        { [styles.isOpen]: isMenuOpen },
        className
      )}
    >
      {label}
      <FontAwesomeIcon
        className={styles.icon}
        icon={isMenuOpen ? faMinus : faPlus}
      />
    </div>
  );
}

export default MenuButton;
