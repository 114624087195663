import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import BreadCrumb from "../../atom/BreadCrumb";
import Button from "../../atom/Button";

import styles from "./HeaderPage.module.scss";

function HeaderPage({
  title,
  background,
  theme,
  showBackButton,
  backLink,
  breadcrumb,
  className,
}) {
  const { isPhone } = useAppContext();
  const navigate = useNavigate();
  return (
    <div
      className={classNames(
        styles.headerPage,
        [styles[theme]],
        { [styles.hasBackground]: !!background },
        className
      )}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={classNames(styles.container, "container")}>
        <BreadCrumb path={breadcrumb} active={title} theme={theme} />
        <div className={styles.content}>
          <h1>{title}</h1>
          {showBackButton && !isPhone && (
            <Button
              {...(backLink
                ? { to: backLink }
                : { onClick: () => navigate(-1) })}
              theme="tertiary"
              size="sm"
            >
              <span className={styles.icon}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </span>
              VOLTAR
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeaderPage;
