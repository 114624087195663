import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import styles from "./LoadOverlay.module.scss";

function LoadOverlay({ className }) {
  const { isLoaderVisible } = useAppContext();

  return isLoaderVisible ? (
    <div className={classNames(styles.loadOverlay, className)}>
      <FontAwesomeIcon className={styles.loader} icon={faSpinner} spin />
    </div>
  ) : (
    ""
  );
}

export default LoadOverlay;
