import classNames from "classnames";
import AccessoryItem from "../../molecule/AccessoryItem";

import styles from "./AccessoriesList.module.scss";

function AccessoriesList({ accessories = [] }) {
  return (
    <section className={styles.accessoriesList}>
      <div className={classNames(styles.container, "container")}>
        <ul className={styles.list}>
          {accessories.map((accessory, index) => (
            <li
              key={`item-${index}`}
              className={classNames(styles.item, {
                [styles.bigger]: index === 6 || index === 7,
              })}
            >
              <AccessoryItem
                {...accessory}
                className={styles.accessoryItem}
                showBiggerImage={index === 6 || index === 7}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default AccessoriesList;
