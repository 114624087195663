import classNames from "classnames";
import { Carousel } from "react-responsive-carousel";
import styles from "./ProductGallery.module.scss";

function ProductGallery({ images = [], className, video }) {
  const mediaItems = images.map(img => ({ type: 'image', src: `/products/${img}` }));
  return (
    <div
      className={classNames(styles.productGallery, className, "productGallery")}
    > 
      <Carousel
        className={styles.images}
        showThumbs
        showIndicators={false}
        showStatus={false}
        showArrows={true}
        infiniteLoop
        swipeable
        thumbWidth={64}
        selectedItem={0}
        moveTo={0}
      >
        {mediaItems.map((item, index) => (
          <div className="carousel-item" style={{width: "100%"}} key={`media-${index}`}>
            {item.type === 'video' ? (
              <iframe width="600" height="350" src={item.src} title="Por que escolher STIHL?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            ) : (
              <img alt={`Imagem ilustrativa produto`} src={item.src} />
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ProductGallery;
