import classNames from "classnames";

import HeaderRoundItems from "../../molecule/HeaderRoundItems";
import RoundItem from "../../molecule/RoundItem";

import styles from "./RoundItems.module.scss";

function RoundItems({
  elementId = "",
  headerBackground,
  headerBackgroundColor,
  headerTitle,
  headerSubtitle,
  headerType,
  items = [],
}) {
  return (
    <section id={elementId} className={styles.roundItems}>
      <HeaderRoundItems
        type={headerType}
        background={headerBackground}
        backgroundColor={headerBackgroundColor}
        title={headerTitle}
        subtitle={headerSubtitle}
      />
      <ul className={classNames(styles.list, "container")}>
        {items.map((item, index) => (
          <li key={`item-${index}`} className={styles.item}>
            <RoundItem
              image={item.image}
              media={item.media}
              title={item.title}
              subtitle={item.subtitle}
              description={item.description}
              isAlignLeft={item.isAlignLeft}
              type={item.type}
              listItems={item.listItems}
              className="roundItem"
            />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default RoundItems;
