import classNames from "classnames";
import styles from "./Flag.module.scss";

function Flag({ label, size = "md", className }) {
  return (
    <div className={classNames(styles.flag, [styles[size]], className)}>
      {label}
    </div>
  );
}

export default Flag;
