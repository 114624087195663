import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./SubMenu.module.scss";

function SubMenu({ submenu, onCloseSubMenu }) {
  return (
    <div className={styles.submenu}>
      <div className={classNames("container", styles.container)}>
        <ul className={styles.list}>
          {submenu.title && (
            <li className={classNames(styles.item, styles.title)}>
              {submenu.title}
            </li>
          )}

          {submenu.menus.map((menu, index) => (
            <li className={styles.item} key={`submenu-item-${index}`}>
              <Link to={menu.link} className={styles.link}>
                {menu.title}
              </Link>
            </li>
          ))}
        </ul>

        <button onClick={onCloseSubMenu} className={styles.close}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>

      <div className="container">
        <div className={styles.all}>
          <Link
            to="/produtos"
            onClick={onCloseSubMenu}
            className={styles.allLink}
          >
            <span>Todos os produtos</span>
            <FontAwesomeIcon
              icon={faAngleRight}
              className={styles.allLinkIcon}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SubMenu;
