import HeaderPage from "../../components/molecule/HeaderPage";
import ProductsList from "../../components/templates/ProductsList";
import styles from "./Products.module.scss";

import products from "../../data/products.json";

function Products() {
  return (
    <div className={styles.products}>
      <HeaderPage title="Produtos" />
      <div className="container">
        <ProductsList products={products} />
      </div>
    </div>
  );
}

export default Products;
