import classNames from "classnames";

import useAppContext from "../../../providers/App";

import ProductGallery from "../../molecule/ProductGallery";
import ProductInfos from "../../molecule/ProductInfos";
import TechnicalTable from "../../molecule/TechnicalTable";
import AccessoriesSlider from "../AccessoriesSlider";
import AttributesList from "../AttributesList";

import styles from "./ProductDetail.module.scss";

function ProductDetail({
  id,
  title,
  images,
  isRelease,
  code,
  description,
  attributes = [],
  accessories = [],
  technical = [],
  video
}) {
  const { isDesktop } = useAppContext();

  return (
    <section className={styles.productDetail}>
      <div className={classNames(styles.productData, "container")}>
        <ProductGallery images={images} video={video} className={styles.productGallery} />
        <ProductInfos
          title={title}
          isRelease={isRelease}
          code={code}
          description={description}
          className={styles.productInfos}
        />
      </div>
      <div className={classNames(styles.technicalDetail, "container")}>
        <TechnicalTable title="DETALHES TÉCNICOS" items={technical} />

        {attributes?.length > 0 && (
          <AttributesList
            attributes={attributes}
            className={styles.attributesList}
          />
        )}

        {accessories?.length > 0 && (
          <AccessoriesSlider
            accessories={accessories}
            startItem={isDesktop ? 1 : 0}
            className={styles.accessoriesSlider}
          />
        )}
      </div>
    </section>
  );
}

export default ProductDetail;
