import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderPage from "../../components/molecule/HeaderPage";
import TipDetail from "../../components/templates/TipDetail";
import styles from "./Tip.module.scss";

import tips from "../../data/tips.json";


function Tip() {
  const navigate = useNavigate();
  const { url } = useParams();
  const [tip, setTip] = useState({});

  useEffect(() => {
    const tip = tips.find((tip) => tip.url === url);

    if (tip) {
      setTip(tip);
    } else {
      navigate("/not-found");
    }
  }, [url]);

  return (
    <div className={styles.tip}>
      <HeaderPage
        breadcrumb={[
          {
            link: "/",
            label: "Página Inicial",
          },
          {
            label: tip.title,
            active: true,
          },
        ]}
        title={tip.title}
        theme="dark"
      />
      <TipDetail
        {...tip}
      />
    </div>
  );
}

export default Tip;
