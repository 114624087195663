import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./BreadCrumb.module.scss";

function BreadCrumb({
  path = [
    {
      link: "/",
      label: "Página Inicial",
    },
  ],
  active,
  theme,
  className,
}) {
  const customActive = path.find((path) => path.active);
  const paths = path.filter((path) => !path.active);
  return (
    <ul className={classNames(styles.breadCrumb, [styles[theme]], className)}>
      {paths.map((route, index) => (
        <li key={`path-${index}`} className={styles.item}>
          <Link className={styles.link} to={route.link}>
            {route.label}
          </Link>
          <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
        </li>
      ))}
      {active && (
        <li className={styles.item}>{customActive?.label || active}</li>
      )}
    </ul>
  );
}

export default BreadCrumb;
