import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useLocation } from "react-router-dom";

import useGaTracker from "../hooks/useGaTracker";

import { checkAnchor } from "../utils/checkAnchor";

const AppContext = createContext();

export function Provider({
  children,
  screenSize,
  isDesktop,
  isTablet,
  isPhone,
}) {
  useGaTracker();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedVideoModal, setSelectedVideoModal] = useState(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isLegalInformationOpen, setIsLegalInformationOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
    setSelectedMenu(null);
    checkAnchor(location.hash);
  }, [location.pathname, location.hash]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AppContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        selectedMenu,
        setSelectedMenu,
        selectedVideoModal,
        setSelectedVideoModal,
        isLoaderVisible,
        setIsLoaderVisible,
        isLegalInformationOpen,
        setIsLegalInformationOpen,
        screenSize,
        isPhone,
        isTablet,
        isDesktop,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default function useAppContext() {
  return useContext(AppContext);
}
