import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import Button from "../../atom/Button";

import styles from "./AccessoryItem.module.scss";

function AccessoryItem({
  title,
  description,
  appliance = [],
  images,
  showBiggerImage,
  className = "",
}) {
  return (
    <div className={classNames(styles.accessoryItem, className)}>
      <div className={styles.image}>
        <img alt={title} src={`/accessories/${images[!showBiggerImage ? 0 : 1]}`} />
      </div>
      <div className={styles.infos}>
        <strong>{title}</strong>
        <p>{description}</p>
        <p className={styles.small}>* Vendido separadamente</p>
        {appliance.length > 0 && (
          <div className={styles.appliance}>
            <span>APLICAÇÃO: </span> {appliance.join(" / ")}
          </div>
        )}
        <Button to="/onde-encontrar" type="link" className={styles.button}>
          <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
          <span>ONDE COMPRAR</span>
        </Button>
      </div>
    </div>
  );
}

export default AccessoryItem;
