import classNames from "classnames";
import StoreItem from "../../molecule/StoreItem";

import styles from "./StoresList.module.scss";

function StoresList({ stores = [] }) {
  return (
    <div className="container">
      <ul className={styles.storesList}>
        {stores.map((store, index) => (
          <li key={`item-${index}`} className={classNames(styles.item)}>
            <StoreItem
              name={store.name}
              state={store.state}
              city={store.city}
              district={store.district}
              postalCode={store.postalCode}
              street={store.street}
              number={store.number}
              complement={store.complement || null}
              phone={store.phone}
              email={store.email}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default StoresList;
