import HeaderPage from "../../components/molecule/HeaderPage";
import Contact from "../../components/templates/Contact";

import styles from "./Contact.module.scss";

import background from "../../assets/images/header-2.jpg";

function ContactPage() {
  return (
    <div className={styles.contact}>
      <HeaderPage
        title="Contato"
        background={background}
        theme="light"
        showBackButton
      />
      <Contact />
    </div>
  );
}

export default ContactPage;
