import VideoItem from "../../molecule/VideoItem";

import styles from "./VideosList.module.scss";

function VideosList({ videos = [] }) {
  return (
    <section className={styles.videosList}>
      <div className="container">
        <ul className={styles.list}>
          {videos.map((video, index) => (
            <li key={`item-${index}`} className={styles.item}>
              <VideoItem {...video} className={styles.videoItem} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default VideosList;
