import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import styles from "./Midias.module.scss";
import XIcon from '../../../assets/svgs/twitter-x.svg';
function Midias({ className }) {
  return (
    <ul className={classNames(styles.midias, className)}>
      <li>
        <a href="https://www.youtube.com/c/StihlBrasil" target="_blank">
          <FontAwesomeIcon
            icon={faYoutube}
            className={styles.icon}
            title="Youtube"
          />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/stihloficia" target="_blank">
          <FontAwesomeIcon
            icon={faInstagram}
            className={styles.icon}
            title="Instagram"
          />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/STIHLBrasil" target="_blank">
          <img src={XIcon} alt="" className={styles.iconx} />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/STIHLBrasilOficial" target="_blank">
          <FontAwesomeIcon
            icon={faFacebookSquare}
            className={styles.icon}
            title="Facebook"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/stihl-ferramentas-motorizadas-ltda/"
          target="_blank"
        >
          <FontAwesomeIcon
            icon={faLinkedin}
            className={styles.icon}
            title="Linkedin"
          />
        </a>
      </li>
    </ul>
  );
}

export default Midias;
