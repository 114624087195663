import classNames from "classnames";
import AttributeBox from "../../molecule/AttributeBox";

import styles from "./AttributesList.module.scss";

function AttributesList({ attributes = [], className }) {
  return (
    <section className={classNames(styles.attributesList, className)}>
      <h4 className={styles.title}>Atributos</h4>
      <ul className={styles.list}>
        {attributes.map((attribute, index) => (
          <li key={`item-${index}`} className={classNames(styles.item)}>
            <AttributeBox {...attribute} className={styles.attributeItem} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default AttributesList;
