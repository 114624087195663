import classNames from "classnames";

import FormGroup from "../../atom/FormGroup";
import Select from "../../atom/Select";

import styles from "./WhereToFindForm.module.scss";

function WhereToFindForm({
  id,
  title,
  description,
  handleChange,
  states,
  cities,
  form,
}) {
  return (
    <section id={id} className={styles.whereToFindForm}>
      <div className={classNames(styles.container, "container")}>
        {title && <h3 className={styles.title}>{title}</h3>}
        <p className={styles.description}>
          {description ||
            `Selecione o seu estado e cidade para encontrar a loja STIHL mais próxima de você.`}
        </p>
        <form className={styles.form}>
          <FormGroup label="ESTADO" className={styles.formGroup}>
            <Select
              onChange={handleChange}
              value={form.state}
              options={states}
              name="state"
              placeholder="Selecione um estado"
            />
          </FormGroup>
          <FormGroup label="CIDADE" className={styles.formGroup}>
            <Select
              onChange={handleChange}
              value={form.city}
              options={cities}
              name="city"
              placeholder="Selecione uma cidade"
            />
          </FormGroup>
        </form>
      </div>
    </section>
  );
}

export default WhereToFindForm;
