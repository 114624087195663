import classNames from "classnames";
import TipBox from "../../molecule/TipBox";
import {useParams} from "react-router-dom";
import styles from "./TipsList.module.scss";

function TipsList({ tips = [], classes = {} }) {
  let { url } = useParams();
  return (
    
    <section id="dicas" className={classNames(styles.tips, classes.section)}>
      <ul className={classNames(styles.list, classes.list)}>
        {tips.map((tip) => 
          (
           tip.url !== url &&
            <li
              key={tip.url}
              className={classNames(styles.item, classes.item)}
            >
                <TipBox {...tip} />
            </li>
          )

        )}
      </ul>
    </section>
  );
}

export default TipsList;
