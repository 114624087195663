import classNames from "classnames";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import AccessoryItem from "../../molecule/AccessoryItem";

import styles from "./AccessoriesSlider.module.scss";

function AccessoriesSlider({ accessories = [], startItem = 0, className }) {
  const { isPhone, isTablet, isDesktop, screenSize } = useAppContext();

  const arrow = (clickHandler, hasNext, type = "prev") => {
    if (!hasNext || (accessories.length < 3 && (isDesktop || isTablet))) return;
    return (
      <button
        onClick={clickHandler}
        className={classNames(
          styles.arrows,
          type === "prev" ? styles.arrowLeft : styles.arrowRight
        )}
      >
        <FontAwesomeIcon icon={type === "prev" ? faAngleLeft : faAngleRight} />
      </button>
    );
  };

  const handleSlidesToShow = () => {
    if (screenSize <= 580) return 100;
    if (isDesktop) return 33.33;
    if (isTablet) return 50;
    if (isPhone) return 70;
  };

  return (
    <section className={classNames(styles.accessoriesSlider, className)}>
      <h3 className={styles.title}>ACESSÓRIOS</h3>
      <Carousel
        selectedItem={accessories.length > 3 ? startItem : 0}
        className={styles.slider}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={accessories.length < 3 ? false : true}
        centerMode={true}
        centerSlidePercentage={handleSlidesToShow()}
        renderArrowPrev={(clickHandler, hasPrev) =>
          arrow(clickHandler, hasPrev, "prev")
        }
        renderArrowNext={(clickHandler, hasNext) =>
          arrow(clickHandler, hasNext, "next")
        }
      >
        {accessories.map((accessory, index) => (
          <AccessoryItem
            {...accessory}
            className={styles.accessoryItem}
            key={`accessory-${index}`}
          />
        ))}
      </Carousel>
    </section>
  );
}

export default AccessoriesSlider;
