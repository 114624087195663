import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import MenuItem from "../../atom/MenuItem";

import { ReactComponent as Logo } from "../../../assets/svgs/logo2.svg";

import styles from "./MenuPromotion.module.scss";

function Menu() {
  return (
    <nav className={styles.menu}>
      <div className={classNames("container", styles.container)}>
        <span className={styles.logo}>
          <Logo className={styles.svg} />
        </span>

        <ul className={styles.nav}>
          <MenuItem
            key="menu-where"
            link="/promocao#where-to-find"
            className={styles.menuItem}
          >
            <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
            <span className={styles.menuItemText}>ENCONTRE UM REVENDEDOR</span>
          </MenuItem>
          <MenuItem
            key="menu-newsletter"
            link="/promocao#newsletter"
            className={styles.menuItem}
          >
            <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
            <span className={styles.menuItemText}>RECEBA NOVIDADES</span>
          </MenuItem>
          <MenuItem
            key="menu-stihl"
            onClick={() => (window.location.href = "https://www.stihl.com.br")}
            className={styles.menuItem}
          >
            <span className={styles.menuItemExternal}>STIHL.COM.BR</span>
          </MenuItem>
        </ul>
      </div>
    </nav>
  );
}

export default Menu;
