import HeaderPage from "../../components/molecule/HeaderPage";
import VideosList from "../../components/templates/VideosList";

import styles from "./Videos.module.scss";

import videos from "../../data/videos.json";
import background from "../../assets/images/header-1.jpg";

function Videos() {
  return (
    <div className={styles.videos}>
      <HeaderPage
        title="Vídeos"
        background={background}
        theme="light"
        showBackButton
      />
      <VideosList videos={videos} />
    </div>
  );
}

export default Videos;
