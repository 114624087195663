import classNames from "classnames";
import Button from "../../atom/Button";

import styles from "./SquareItem.module.scss";

function SquareItem({ url, images, title, resume, className = "" }) {
  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'clique',
      'eventLabel': {title},
      'eventValue': ''
    });
  };
  return (
    <div className={classNames(styles.squareItem, className)}>
      <div className={styles.image}>
        <img alt={title} src={`/tips/${images}`} />
      </div>
      <div className={styles.infos}>
        {title && <strong className={styles.title}>{title}</strong>}
        {resume && (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: resume }}
          />
        )}
        {url && (
          <Button
            onClick={handleClick}
            className={styles.button}
            to={`/dicas/${url}`}
            theme="secondary"
            size="sm"
          >
            LEIA MAIS
          </Button>
        )}
      </div>
    </div>
  );
}

export default SquareItem;
