export const updateForm = (e, state, setState) => {
  setState({
    ...state,
    [e.target.name]: e.target.value,
  });
};

export const validateForm = (fields, values) => {
  const formFields = Object.keys(fields);
  const fieldsErrors = {}

  for (let i = 0; i < formFields.length; i++) {
    const field = formFields[i];
    fieldsErrors[field] = !values[field];
  }

  return {
    hasError: Object.values(fieldsErrors).some(field => field),
    errors: fieldsErrors
  }
}

export const changeErrors = (fields, values) => {
  const errors = { ...fields }
  const formFields = Object.keys(errors);
  for (let i = 0; i < formFields.length; i++) {
    const field = formFields[i];
    if (values[field]) {
      errors[field] = false;
    }
  }

  return { ...errors }
}