import classNames from "classnames";
import Button from "../../atom/Button";

import styles from "./Post.module.scss";


function Post({ title, subtitle, description, image, link, className }) {

  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'clique',
      'eventLabel': 'conheca_todos_os_acessorios',
      'eventValue': ''
    });

  };

  return (
    <div className={classNames(styles.post, className)}>
      <div className={classNames(styles.container, "container")}>
        <div className={styles.infos}>
          {title && <h3>{title}</h3>}
          {subtitle && <h4>{subtitle}</h4>}
          {description && <p>{description}</p>}
          {link && (
            <Button onClick={handleClick} className={styles.button} to={link} theme="secondary">
              CONHEÇA TODOS OS ACESSÓRIOS
            </Button>
          )}
        </div>
        {image && <img src={image} className={styles.image} alt={title} />}
      </div>
    </div>
  );
}

export default Post;
