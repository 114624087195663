import { useLocation } from "react-router-dom";
import HeaderLinks from "../../molecule/HeaderLinks";
import Menu from "../../molecule/Menu";
import MenuPromotion from "../../molecule/MenuPromotion";

import "./Header.scss";

function Header() {
  const location = useLocation();
  return (
    <header>
      {location.pathname === "/promocao" ? (
        <MenuPromotion />
      ) : (
        <>
          <HeaderLinks />
          <Menu />
        </>
      )}
    </header>
  );
}

export default Header;
