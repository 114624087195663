import classNames from "classnames";

import styles from "./TechnicalTable.module.scss";

function TechnicalTable({ title, items = [], className }) {
  return (
    <div className={classNames(styles.technicalTable, className)}>
      {title && <h4 className={styles.title}>{title}</h4>}
      {!!items.length && (
        <ul className={styles.table}>
          {items.map((item, index) => (
            <li className={styles.row} key={`row-${index}`}>
              {item.map((value, i) => (
                <div className={styles.col} key={`item-${i}`}>
                  {value}
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default TechnicalTable;
