import classNames from "classnames";
import styles from "./Select.module.scss";

function Select({
  name,
  options = [],
  placeholder,
  nameIndex,
  valueIndex,
  value,
  onChange,
  hasError,
  className,
}) {
  return (
    <select
      name={name}
      onChange={onChange}
      className={classNames(styles.select, className, {
        [styles.hasError]: hasError,
      })}
      value={value}
    >
      {placeholder && (
        <option value="" key="0">
          {placeholder}
        </option>
      )}
      {options.map((option, i) => {
        return (
          <option value={valueIndex ? option[valueIndex] : option} key={i + 1}>
            {nameIndex ? option[nameIndex] : option}
          </option>
        );
      })}
    </select>
  );
}

export default Select;
