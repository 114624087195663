import classNames from "classnames";
import WhereToFindeStripe from "../../molecule/WhereToFindeStripe";
import TipInfos from "../../molecule/TipInfos";
import styles from "./TipDetail.module.scss";

import TipsList from "../../templates/TipsList";

import tips from "../../../data/tips.json";

function TipDetail({
  title,
  images,
  resume,
  content,
  list,
  videoUrl,
  url
}) {
  return (
    <section className={styles.tipDetail}>
      <div className={classNames("container")}>
        <TipInfos
          title={title}
          resume={resume}
          list={list}
          images={images}
          content={content}
          videoUrl={videoUrl}
          url={url}
        />
      </div>
      <WhereToFindeStripe
          type="tertiary"
          title=""
          subtitle="SE INTERESSOU POR ALGUMA LAVADORA STIHL?"
          buttonText="ENCONTRE UM REVENDEDOR"
          buttonLink="/onde-encontrar"
          buttonSize="md"
        />

      <TipsList tips={tips} />

    </section>
  );
}

export default TipDetail;
