import classNames from "classnames";

import useAppContext from "../../../providers/App";

import Modal from "../Modal";

import styles from "./ModalLegalInformation.module.scss";

function ModalLegalInformation({ className }) {
  const { isLegalInformationOpen, setIsLegalInformationOpen } = useAppContext();
  return (
    <Modal
      isOpen={isLegalInformationOpen}
      closeModal={() => setIsLegalInformationOpen(false)}
    >
      <div className={classNames(styles.modalLegalInformation, className)}>
        <div>
          <p>
            A STIHL agradece a sua visita à nossa Página{" "}
            <a href="https://lavadoras.stihl.com.br">
              https://lavadoras.stihl.com.br
            </a>{" "}
            (“Página”) e o interesse demonstrado na nossa empresa e nos nossos
            produtos. Nesta Informação Legal Você tem acesso a informações
            resumidas da Política de Privacidade da STIHL (“Política”), mais
            especificamente sobre a sua navegação nesta Página e sobre os
            cookies utilizados.
          </p>
          <p>
            A{" "}
            <a
              target="_blank"
              href="https://crm.stihl.com.br/privacidade/STIHL_Pol%C3%ADtica%20de%20Privacidade%20Geral%2007092020.pdf?_ga=2.151538992.846650278.1603712000-147512325.1593118451"
            >
              Política de Privacidade da STIHL
            </a>{" "}
            (“Política”), na íntegra, com todos os detalhamentos sobre os
            Tratamentos realizados pela STIHL com os Dados Pessoais de clientes,
            consumidores, revendedores e importadores, além de outras
            informações relacionadas à privacidade e proteção de Dados Pessoais,
            podem ser encontradas no Portal de Privacidade localizado em{" "}
            <a href="https://www.stihl.com.br/protecao-de-dados-pessoais.aspx">
              https://www.stihl.com.br/protecao-de-dados-pessoais.aspx
            </a>
            . As informações e Dados Pessoais apurados na sua visita à Página,
            inclusive aqueles fornecidos por meio do preenchimento de
            formulários, são processados de forma confidencial e em conformidade
            com as disposições legais. Na Política estão especificados os
            princípios que seguimos no tratamento, as finalidades para as quais
            tratamos seus dados, os tipos de dados que coletamos e as medidas de
            proteção adotadas para a coleta, uso, armazenamento, segurança e
            confidencialidade dos seus dados pessoais. Recomendamos que Você
            leia e compreenda a Política e esta Informação Legal antes de
            começar a utilizar a Página.
          </p>
          <h3>1. DEFINIÇÕES</h3>
          <p>
            Sempre que os termos abaixo forem utilizados nesta Política com a
            letra maiúscula, terão o significado abaixo:
          </p>
          <p>
            • “Dados Pessoais”: informação relacionada a pessoa natural
            identificada ou identificável.
          </p>
          <p>
            • “Dados Pessoais Sensíveis”: dado pessoal sobre origem racial ou
            étnica, convicção religiosa, opinião política, filiação a sindicato
            ou a organização de caráter religioso, filosófico ou político, dado
            referente à saúde ou à vida sexual, dado genético ou biométrico,
            quando vinculado a uma pessoa natural.
          </p>
          <p>
            • “Dados Anonimizados”: dado relativo a um titular que não possa ser
            identificado, considerando a utilização de meios técnicos razoáveis
            e disponíveis na ocasião de seu tratamento.
          </p>
          <p>
            • “Tratamento”: toda operação realizada com dados pessoais, como as
            que se referem a coleta, produção, recepção, classificação,
            utilização, acesso, reprodução, transmissão, distribuição,
            processamento, arquivamento, armazenamento, eliminação, avaliação ou
            controle da informação, modificação, comunicação, transferência,
            difusão ou extração.
          </p>
          <p>
            • “Legislação Aplicável de Proteção de Dados”: a Lei Geral de
            Proteção de Dados Pessoais Brasileira N°13.709/2018 (“LGPD”) e
            alterações posteriores, bem como o Marco Civil da Internet
            N°12.965/2014, Decreto N°8.771/2016, Constituição Federal, Código
            Civil Brasileiro, Código Penal, Código de Defesa do Consumidor,
            Decreto N°7.963/2013 e quaisquer outras leis e regulamentos em
            relação ao Tratamento de Dados Pessoais e privacidade que são
            aplicáveis à uma Parte e, se aplicável, todas as orientações e
            códigos de prática emitidos pela Autoridade Nacional de Proteção de
            Dados (“ANPD”) ou outra autoridade de supervisão ou proteção de
            dados pertinente.
          </p>
          <p>
            • “Usuário” ou “Você”: qualquer pessoa física que venha a utilizar a
            Página, incluindo, mas não se limitando a clientes, consumidores,
            Pontos de Venda e fornecedores que acessem a Página.
          </p>
          <h3>2. QUEM SOMOS</h3>
          <p>
            A STIHL Ferramentas Motorizadas Ltda. ("STIHL"), sociedade
            empresária com sede na Av. São Borja, nº 3000, Distrito Industrial,
            São Leopoldo – RS, é responsável pelos Dados Pessoais que Você
            compartilha conosco. Quando dizemos “STIHL”, “nós”, “nosso” ou
            “nos”, é a esta empresa que nos referimos. A STIHL é o “controlador
            de dados” para os fins da Legislação Aplicável de Proteção de Dados.
          </p>
          <h3>3. TRATAMENTO DE DADOS ANONIMIZADOS</h3>
          <p>
            Tratamos Dados Anonimizados com a finalidade de gerar relatórios
            estatísticos sobre o uso da Página e outras plataformas, para melhor
            entender a interação dos usuários com nossas ferramentas. A Página
            coleta e armazena informações como: data e hora da última vez que o
            usuário visitou a Página, desenho de conteúdos que o usuário
            escolheu na sua primeira visita à Página, elementos de segurança que
            interferem no controle de acesso às áreas restringidas e outras
            informações similares. Desta maneira podemos elaborar estatísticas
            sobre os países e servidores que visitam com mais frequência a
            Página, averiguar as horas de maior afluência, fazer ajustes
            precisos para evitar problemas de saturação nas horas de maior
            tráfego à Página, conhecer a efetividade de “banners” e ligações
            dirigidas ao nosso servidor com a finalidade de oferecer melhores
            resultados comerciais, conhecer as áreas de maior êxito e aumentar e
            melhorar o seu conteúdo com a finalidade de que os usuários obtenham
            conteúdos mais satisfatórios.
          </p>
          <p>
            As informações discriminadas acima são totalmente anônimas,
            portanto, não armazenam informações pessoais identificáveis, e em
            nenhum caso poderão ser associadas a um usuário concreto e
            identificado.
          </p>
          <h3>4. DURAÇÃO DO TRATAMENTO</h3>
          <p>
            De forma geral, conservamos seus Dados Pessoais enquanto Você
            estiver usando os Serviços e durante um período razoável após o seu
            uso para fins de registro histórico, para cumprir nossas obrigações
            legais ou regulatórias ou para permitir e garantir o exercício
            regular de nossos direitos ou de terceiros. Quando todas as
            finalidades aplicáveis ao Tratamento dos seus Dados Pessoais tiverem
            sido alcançadas, eles serão removidos de nossos sistemas e registros
            ou anonimizados, para que Você não possa mais ser identificado a
            partir desses dados.
          </p>
          <h3>5. TRATAMENTO DE DADOS DE CRIANÇAS</h3>
          <p>
            Os Serviços da STIHL não foram formulados nem são destinados a
            indivíduos menores de 12 anos. Portanto, se Você for menor de 12
            anos, solicitamos que Você não use os Serviços, deixe a Página
            imediatamente e não nos forneça qualquer informação. Se Você tiver
            conhecimento de que um indivíduo menor de 12 anos nos forneceu
            informações, por favor, entre em contato conosco no endereço de
            e-mail{" "}
            <a href="mailto:privacidade@stihl.com.br">
              {" "}
              privacidade@stihl.com.br
            </a>{" "}
            para que possamos adotar as providências necessárias para a exclusão
            de tais dados.
          </p>
          <h3>6. SEGURANÇA DOS DADOS</h3>
          <p>
            A STIHL adota meios técnicos e procedimentos de segurança
            apropriados para proteger seus Dados Pessoais contra o uso indevido,
            acesso não autorizado, interferência, alteração, divulgação ou
            perda, através de medidas como o uso de criptografia, limitação do
            acesso físico às nossas instalações, limitação do acesso às
            informações coletadas, dentre outras medidas, de modo a cumprir com
            suas obrigações quanto à confidencialidade e inviolabilidade dos
            seus Dados.
          </p>
          <p>
            Como não controlamos a segurança da Internet ou de outras redes que
            Você utiliza para acessar a Página, a STIHL não é responsável pela
            segurança das informações que Você escolher comunicar online
            enquanto Você está navegando e transmitindo dados, inclusive por
            qualquer perda de dados durante a transmissão. Assim, uma vez que
            não é possível garantir a completa segurança dos dados transmitidos
            à Página, pedimos que tenha cuidado e atenção com a forma de
            transmissão e com a escolha dos Dados Pessoais que são
            compartilhados conosco.
          </p>
          <h3>7. DADOS DE NAVEGAÇÃO DA PÁGINA E COOKIES</h3>
          <p>
            Quando você visita a nossa Página, armazenamos o seu provedor de
            serviços de Internet, o website de onde você originou, a data e a
            duração de sua visita, informações sobre o dispositivo (marca,
            modelo e sistema operacional) e o navegador utilizado. Também
            coletamos seu endereço IP, cujos quatro últimos dígitos são, no
            entanto, anônimos.
          </p>
          <p>
            Processamos esses dados de navegação para facilitar o seu acesso aos
            nossos Serviços e para identificar e interromper qualquer uso
            indevido. Ainda utilizamos dados de navegação de forma anonimizada
            para fins estatísticos e para aprimorar a nossa Página.
          </p>
          <p>
            A Página utiliza cookies. Os cookies são pequenos arquivos de texto
            que são armazenados no seu equipamento (computador ou outro
            dispositivo) por meio de um navegador da Internet, quando visita
            determinados sites. Eles nos permitem personalizar a sua experiência
            on-line e fornecer serviços mais amigáveis, facilitando a
            utilização. De modo a melhorar a navegabilidade da nossa Página,
            levamos em consideração as suas preferências e processamos os dados
            para fins de aperfeiçoamento de performance, análise e, em alguns
            casos, marketing.
          </p>
          <p>
            Alguns dos cookies que usamos são excluídos no final da sessão do
            navegador, ou seja, uma vez que o navegador é fechado. Outros
            cookies permanecem em seu dispositivo, permitindo que nós ou nossos
            parceiros reconheçamos seu navegador em sua próxima visita. Os
            cookies não podem ser usados para fornecer acesso a outros arquivos
            em seu computador ou para determinar seu endereço de e-mail.
          </p>
          <p>
            Além de cookies próprios, a Página pode também utilizar cookies de
            terceiros, para fins de serviços de análises, marketing e plug-ins,
            Os cookies da Página são divididos em cookies essenciais, cookies de
            análise e cookies de marketing. A base legal para o Tratamento de
            dados pessoais obtidos pelos cookies essenciais à navegação é o
            legítimo interesse. A base legal para o Tratamento de dados pessoais
            obtidos por cookies de análise e de marketing é o consentimento.
          </p>
          {/* <p>
            Confira os cookies utilizados na Página na{" "}
            <a href="#cookiesList" id="button-cookie">
              {" "}
              lista de cookies
            </a>
            .
          </p> */}
          <p>
            <span
              id="ot-sdk-btn"
              className={classNames(
                "ot-sdk-show-settings",
                "optanon-show-settings",
                styles.cooliesLink
              )}
            >
              Você poderá revogar o seu consentimento a qualquer momento sem
              qualquer motivo clicando aqui.
            </span>
          </p>
          <p>
            Lembre-se de que os cookies não danificam o seu dispositivo
            (computador, tablet, smartphone, etc). Fornecemos-lhe esta
            informação no cumprimento das disposições legais aplicáveis
            atualmente e garantimos o respeito e proteção da sua privacidade
            quando utiliza a Página. Se pretender saber mais sobre cookies em{" "}
            <a href="https://aboutcookies.org/">aboutcookies.org</a>.
          </p>
          <p>
            Quanto à gestão dos cookies, a maioria dos navegadores são
            configurados para aceitar cookies automaticamente. Uma vez que estas
            configurações forem guardadas no seu navegador, todos os processos
            serão executados em segundo plano sem que sejam percebidos. No
            entanto, Você pode querer alterar essas configurações. É possível
            impedir a inserção de cookies a qualquer momento por meio de um
            ajuste correspondente do navegador de Internet utilizado e,
            portanto, opor-se permanentemente. Contudo, ao fazê-lo, Você pode
            não ser capaz de navegar na nossa Página ou de aproveitar todas as
            suas características.
          </p>
          <p>
            Ressaltamos que as disposições constantes deste item contemplam
            apenas esta Página específica, portanto, caso Você venha a interagir
            com a STIHL em outras plataformas e domínios disponíveis, poderão
            haver alterações nos dados de navegação e/ou nos cookies utilizados,
            devendo ser consultadas as informações específicas de cada
            plataforma.
          </p>
          <h3>8. SEUS DIREITOS</h3>
          <p>
            Você possui os seguintes direitos em relação aos seus Dados
            Pessoais:
          </p>
          <p>
            • <strong>Confirmação da existência do tratamento.</strong> Você tem
            o direito de nos perguntar se tratamos seus Dados Pessoais.
          </p>
          <p>
            • <strong>Informação.</strong> Você tem o direito de receber
            informações sobre o tratamento de seus Dados Pessoais, inclusive
            sobre as entidades públicas e privadas com as quais compartilhamos
            seus dados.
          </p>
          <p>
            • <strong>Acesso.</strong> Com algumas exceções destinadas a
            proteger os direitos de terceiros, Você tem o direito de acessar os
            Dados Pessoais que temos sobre Você ou, em alguns casos, de receber
            uma cópia desses dados em formato eletrônico.
          </p>
          <p>
            • <strong>Correção.</strong> Você tem o direito de corrigir os Dados
            Pessoais que mantemos sobre Você, se estiverem incorretos,
            incompletos ou desatualizados.
          </p>
          <p>
            • <strong>Eliminação.</strong> Você tem o direito de que seus Dados
            Pessoais sejam excluídos sempre que forem tratados sob o seu
            consentimento. Em geral, esse direito não está disponível quando
            ainda temos um motivo legal válido para manter seus dados (por
            exemplo, porque somos forçados a fazê-lo por uma obrigação legal).
          </p>
          <p>
            • <strong>Anonimização.</strong> Você tem o direito de se opor ao
            tratamento e de solicitar a anonimização, bloqueio ou eliminação de
            Dados desnecessários ou excessivos, ou de Dados tratados em não
            conformidade com as disposições da LGPD.
          </p>
          <p>
            • <strong>Portabilidade.</strong> Em algumas circunstâncias, Você
            tem o direito de solicitar a transferência dos Dados que coletamos
            para outra organização ou diretamente para Você, se aplicável e
            conforme regulamentado pela ANPD.
          </p>
          <p>
            • <strong>Revogação do consentimento.</strong> Se estivermos
            tratando seus Dados Pessoais com base em seu consentimento, Você tem
            o direito de revogar este consentimento a qualquer momento, caso em
            que interromperemos o tratamento, exceto se tivermos outra base
            legal para continuar. Você também tem o direito de ser informado
            sobre a possibilidade de não dar consentimento e suas implicações.
          </p>
          <p>
            • <strong>Reclamação.</strong> Você tem o direito de entrar em
            contato e/ou peticionar à Autoridade Nacional de Proteção de Dados
            (ANPD) para apresentar uma reclamação contra as práticas de proteção
            de dados e privacidade da STIHL. Por favor, não hesite em entrar em
            contato conosco utilizando as informações abaixo antes de apresentar
            qualquer reclamação à autoridade de proteção de dados competente.
          </p>
          <h3>9. CONTATO</h3>
          <p>
            Se Você tiver alguma dúvida ou preocupação sobre como tratamos e
            armazenamos seus Dados Pessoais, ou gostaria de exercer qualquer dos
            seus direitos acima, entre em contato conosco por meio do nosso
            Portal de Privacidade em{" "}
            <a
              href="https://www.stihl.com.br/protecao-de-
                                                      dados-pessoais.aspx"
            >
              https://www.stihl.com.br/protecao-de- dados-pessoais.aspx
            </a>{" "}
            ou escrevendo para o nosso Encarregado de Proteção de Dados em{" "}
            <a href="mailto:privacidade@stihl.com.br">
              privacidade@stihl.com.br
            </a>
            .
          </p>
          <p>
            Informação Legal –{" "}
            <a href="http://lavadoras.stihl.com.br">
              http://lavadoras.stihl.com.br
            </a>{" "}
            – 20 de Outubro de 2020
          </p>
        </div>
        {/* <ul id="cookiesList" className={styles.cookiesList}>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> OptanonAlertBoxClosed
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies essenciais
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> ul
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> www.facebook.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> YSC
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> youtube.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> test_cookie
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> doubleclick.net
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _ga
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              derricador.stihl.com.br, lavadoras.stihl.com.br,
              ofertas.stihl.com.br, produtosabateria.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _fbp
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> IDE
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> doubleclick.net
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjIncludedInPageviewSample
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _fbp
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> VISITOR_INFO1_LIVE
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> youtube.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _gat_UA-
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjid
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _gid
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              derricador.stihl.com.br, lavadoras.stihl.com.br,
              ofertas.stihl.com.br, produtosabateria.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> fr
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              jardimdasideias.com.br, lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> facebook.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> com.silverpop.iMA.page_visit
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br,
              produtosabateria.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> com.silverpop.iMAWebCookie
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br,
              produtosabateria.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjIncludedInPageviewSample
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> script.hotjar.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> OptanonConsent
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> .lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies essenciais
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _ga
            </p>
            <p>
              <strong>Domínio:</strong> comprafacil.stihl.com.br,
              derricador.stihl.com.br, jardimdasideias.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br,
              produtosabateria.stihl.com.br, stihlpromocoes.com.br
            </p>
            <p>
              <strong>Hostname:</strong> com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> GPS
            </p>
            <p>
              <strong>Domínio:</strong> jardimdasideias.com.br,
              lavadoras.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> youtube.com
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjAbsoluteSessionInProgress
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de análise
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> com.silverpop.iMA.session
            </p>
            <p>
              <strong>Domínio:</strong> derricador.stihl.com.br,
              lavadoras.stihl.com.br, ofertas.stihl.com.br,
              produtosabateria.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies de marketing
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjTLDTest
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> stihl.com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies essenciais
            </p>
          </li>
          <li className={styles.cookieItem}>
            <p>
              <strong>Nome do cookie:</strong> _hjTLDTest
            </p>
            <p>
              <strong>Domínio:</strong> lavadoras.stihl.com.br,
              ofertas.stihl.com.br
            </p>
            <p>
              <strong>Hostname:</strong> com.br
            </p>
            <p>
              <strong>Objetivo:</strong> Cookies essenciais
            </p>
          </li>
        </ul> */}
      </div>
    </Modal>
  );
}

export default ModalLegalInformation;
