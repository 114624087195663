import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import Button from "../../atom/Button";

import whereImage from "../../../assets/images/where-to-find.png";

import styles from "./WhereToFindeStripe.module.scss";

function WhereToFindeStripe({
  title,
  subtitle,
  buttonText,
  buttonLink,
  buttonSize = "sm",
  type = "primary",
  className = "",
}) {
  const { isDesktop } = useAppContext();

  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'clique',
      'eventLabel': 'encontre_um_revendedor',
      'eventValue': ''
    });
  };

  return (
    <div className={classNames(styles.whereToFinde, className, styles[type])}>
      <div className={classNames(styles.container, "container")}>
        {type === "primary" && (
          <img src={whereImage} className={styles.image} />
        )}

        <div className={styles.infos}>
          <div className={styles.texts}>
            <p className={styles.title}>{title}</p>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
          <Button
            onClick={handleClick}
            className={styles.button}
            to={buttonLink}
            theme={type === "primary" ? "tertiary" : "primary"}
            size={!isDesktop ? "sm" : buttonSize}
          >
            <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WhereToFindeStripe;
