import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import Flag from "../../atom/Flag";
import Button from "../../atom/Button";

import styles from "./ProductBox.module.scss";

function ProductBox({ id, title, title_ga, resume, images, isRelease }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/produtos/${id}`);
    if( title_ga !== '') {
      const dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'CTA',
        'eventAction': 'clique',
        'eventLabel': title_ga,
        'eventValue': ''
      });
    }
  };

  return (
    <div onClick={handleClick} className={styles.productBox}>
      <div className={styles.image}>
        <img alt={title} src={`/products/${images[0]}`} />
      </div>
      <div className={styles.flag}>
        {isRelease && <Flag label="LANÇAMENTO" size="sm" />}
      </div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{resume}</p>
      <Button
        to={`/produtos/${id}`}
        type="link"
        theme="secondary"
        className={styles.button}
      >
        <div className={styles.icon}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        SAIBA MAIS
      </Button>
    </div>
  );
}

export default ProductBox;
