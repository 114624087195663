import classNames from "classnames";

import IconBall from "../../atom/IconBall";

import styles from "./ContactInfoBlock.module.scss";

function ContactInfoBlock({
  icon,
  title,
  subtitle,
  description,
  onClick,
  className,
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.contactInfoBlock, className, {
        [styles.hasOnClick]: !!onClick,
      })}
    >
      <IconBall icon={icon} className={styles.icon} />
      {title && <h5>{title}</h5>}
      {subtitle && <strong>{subtitle}</strong>}
      {description && <p>{description}</p>}
    </div>
  );
}

export default ContactInfoBlock;
