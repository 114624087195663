import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import GTM from "react-gtm-module";

import { Provider as AppContextProvider } from "./providers/App";

import Header from "./components/templates/Header";
import Footer from "./components/templates/Footer";

import HomePage from "./pages/Home/Home";
import ProductsPage from "./pages/Products/Products";
import ProductPage from "./pages/Product/Product";
import TipsPage from "./pages/Tips/Tips";
import TipPage from "./pages/Tip/Tip";
import VideosPage from "./pages/Videos/Videos";
import AccessoriesPage from "./pages/Accessories/Accessories";
import WhereToFindPage from "./pages/WhereToFind/WhereToFind";
import ContactPage from "./pages/Contact/Contact";
//import Promotion from "./pages/Promotion/Promotion";
import NotFoundPage from "./pages/NotFound/NotFound";

import ModalVideo from "./components/molecule/ModalVideo";
import ModalLegalInformation from "./components/molecule/ModalLegalInformation";
import LoadOverlay from "./components/atom/LoadOverlay";

function App() {
  const [screenSize, setScreenSize] = useState(window ? window.innerWidth : 0);

  useEffect(() => {
    GTM.initialize({ gtmId: "GTM-KQ69SS5" });
    setScreenSize(window.innerWidth);
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );

    window.addEventListener("resize", () => {
      setScreenSize(window.innerWidth);
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    });

    return () =>
      window.removeEventListener("resize", () => {
        setScreenSize(window.innerWidth);
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight * 0.01}px`
        );
      });
  }, []);

  return (
    <BrowserRouter>
      <AppContextProvider
        screenSize={screenSize}
        isDesktop={screenSize > 1000}
        isTablet={screenSize > 768 && screenSize <= 1000}
        isPhone={screenSize <= 768}
      >
        <Header />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/produtos" element={<ProductsPage />} />
          <Route exact path="/produtos/:id" element={<ProductPage />} />
          <Route exact path="/dicas" element={<TipsPage />} />
          <Route exact path="/dicas/:url" element={<TipPage />} />
          <Route exact path="/videos" element={<VideosPage />} />
          <Route exact path="/acessorios" element={<AccessoriesPage />} />
          <Route exact path="/onde-encontrar" element={<WhereToFindPage />} />
          <Route exact path="/promocao" element={<NotFoundPage />} />
          <Route exact path="/contato" element={<ContactPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ModalVideo />
        <ModalLegalInformation />
        <LoadOverlay />
        <Footer />
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
