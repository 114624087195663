import HeaderPage from "../../components/molecule/HeaderPage";

import styles from "./NotFound.module.scss";

import background from "../../assets/images/header-2.jpg";

function NotFound() {
  return (
    <div className={styles.contact}>
      <HeaderPage
        title="Página não encontrada"
        background={background}
        theme="light"
        showBackButton
      />
    </div>
  );
}

export default NotFound;
