import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import styles from "./MenuItem.module.scss";

function MenuItem({
  children,
  title,
  link,
  hasSubmenu = false,
  isActive,
  onClick,
  className,
}) {
  return (
    <li className={classNames(styles.menuItem, { [styles.active]: isActive })}>
      {link && (
        <Link to={link} className={classNames(styles.link, className)}>
          {children || title}
        </Link>
      )}

      {onClick && (
        <span onClick={onClick} className={classNames(styles.link, className)}>
          {children || title}
          {hasSubmenu && (
            <FontAwesomeIcon
              className={styles.icon}
              icon={isActive ? faMinus : faPlus}
            />
          )}
        </span>
      )}
    </li>
  );
}

export default MenuItem;
