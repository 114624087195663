import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./IconBall.module.scss";

function IconBall({ icon, className }) {
  return (
    <div className={classNames(styles.iconBall, className)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
}

export default IconBall;
