import classNames from "classnames";

import HeaderSquareItems from "../../molecule/HeaderSquareItems";
import SquareItem from "../../molecule/SquareItem";

import styles from "./SquareItems.module.scss";

function SquareItems({
  headerBackground,
  headerBackgroundColor,
  headerTitle,
  headerSubtitle,
  headerType,
  tips
}) {
  return (
    <section id="dicas" className={styles.squareItems}>
      <HeaderSquareItems
        type={headerType}
        background={headerBackground}
        backgroundColor={headerBackgroundColor}
        title={headerTitle}
        subtitle={headerSubtitle}
      />
      <ul className={classNames(styles.list, "container")}>
        {tips.map((tip, index) => (
          <li key={`item-${index}`} className={styles.item}>
            <SquareItem
              {...tip}
            />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default SquareItems;
