import classNames from "classnames";

import useAppContext from "../../providers/App";

import Banner from "../../components/molecule/Banner";
import Videos from "../../components/templates/Videos";
import ProductsList from "../../components/templates/ProductsList";
import WhereToFindeStripe from "../../components/molecule/WhereToFindeStripe";
import RoundItems from "../../components/templates/RoundItems";
import Post from "../../components/molecule/Post";
import SquareItems from "../../components/templates/SquareItems";


import featureImage from "../../assets/images/feature-image.jpg";
import advantageImage from "../../assets/images/bg-advantages.jpg";
import advantageImage2 from "../../assets/images/bg-advantages-2.jpg";
import postImage from "../../assets/images/post.jpg";

import videos from "../../data/videos.json";
import products from "../../data/products.json";
import tips from "../../data/tips.json";


import styles from "./Home.module.scss";

function Home() {
  const { isDesktop } = useAppContext();

  return (
    <main className={styles.home}>
      <h1 style={{display: 'none'}} >
        Lavadoras
      </h1>
      <Banner
        banners={[
          {
            bannerLink: "/onde-encontrar",
            image: "/banners/1.jpg",
            imageMobile: "/banners/1-mobile.jpg",
          },
          {
            bannerLink: "/acessorios",
            image: "/banners/2.jpg",
            imageMobile: "/banners/2-mobile.jpg",
          },
          
        ]}
      />

      <div className={styles.featureText}>
        <h2>POTÊNCIA, PRATICIDADE E EFICIÊNCIA</h2>
        <p>que você só encontra nas ferramentas STIHL.</p>
      </div>

      <Videos videos={videos} startItem={isDesktop ? 1 : 0} />

      <RoundItems
        headerBackground={advantageImage}
        headerTitle="COMPRAR UMA FERRAMENTA STIHL É PODER CONTAR SEMPRE"
        headerSubtitle="COM A QUALIDADE E DURABILIDADE DE PRODUTOS DIFERENCIADOS."
        headerType="secondary"
        items={[
          {
            image: "/advantages/advantage-1.jpg",
            media: [
              "/advantages/advantage-1.mp4",
              "/advantages/advantage-1.webm",
            ],
            title: "Maior pressão e vazão da categoria",
            isAlignLeft: true,
            type: "tertiary",
          },
          {
            image: "/advantages/advantage-2.jpg",
            media: [
              "/advantages/advantage-2.mp4",
              "/advantages/advantage-2.webm",
            ],
            title: "Faz mais em menos tempo",
            isAlignLeft: true,
            type: "tertiary",
          },
          {
            image: "/advantages/advantage-3.jpg",
            media: [
              "/advantages/advantage-3.mp4",
              "/advantages/advantage-3.webm",
            ],
            title: "2 anos de garantia*",
            isAlignLeft: true,
            type: "tertiary",
          },
        ]}
      />

      <div className={styles.featureImage}>
        <img alt="Imagem ilustrativa - Feature" src={featureImage} />
      </div>

      <div className={classNames(styles.products, "container")}>
        <div className={styles.title}>
          <h2>PARA CADA NECESSIDADE, UMA LAVADORA STIHL.</h2>
          <p>ENCONTRE AQUI O MODELO IDEAL PARA FAZER A SUA LIMPEZA.</p>
        </div>
        <ProductsList products={products} />
      </div>

      <Post
        title="ACESSÓRIOS "
        subtitle="PARA POTENCIALIZAR A SUA LAVADORA STIHL"
        description="Desenvolvidas para facilitar o trabalho de limpeza, as lavadoras STIHL tornam as atividades muito mais simples e eficientes, proporcionando economia de água. E com os acessórios certos elas podem oferecer ainda mais praticidade."
        image={postImage}
        link="/acessorios"
        className={styles.post}
      />

      <div className={styles.waterBg}>
        <RoundItems
          elementId="advantages"
          headerBackground={advantageImage2}
          headerTitle="ECONOMIZE TEMPO E ESFORÇO NA HORA DE ESCOLHER."
          headerSubtitle="CONHEÇA AS PRINCIPAIS VANTAGENS DAS LAVADORAS STIHL."
          headerType="primary"
          items={[
            {
              image: "/advantages/advantage-4.jpg",
              media: [
                "/advantages/advantage-4.mp4",
                "/advantages/advantage-4.webm",
              ],
              title: "Maior pressão e vazão da categoria",
              isAlignLeft: true,
              type: "secondary",
              listItems: [
                "<b>- Engate rápido</b>",
                "<b>- Sistema anti-torção</b>",
                "<b>- Acionamentos simples</b>",
              ],
            },
            {
              image: "/advantages/advantage-5.jpg",
              media: [
                "/advantages/advantage-5.mp4",
                "/advantages/advantage-5.webm",
              ],
              title: "Maior pressão e vazão da categoria",
              isAlignLeft: true,
              type: "secondary",
              listItems: [
                "<b>- 6 metros de mangueira.</b>",
                "<b>- Fácil de transportar, graças à altura da alça e o tamanho das rodas.</b>",
              ],
            },
            {
              image: "/advantages/advantage-6.jpg",
              media: [
                "/advantages/advantage-6.mp4",
                "/advantages/advantage-6.webm",
              ],
              title: "Maior pressão e vazão da categoria",
              isAlignLeft: true,
              type: "secondary",
              listItems: [
                "<b>- Compacta</b>",
                "<b>- Encaixes fáceis e precisos</b>",
                "<b>- Compartimentos especiais para guardar acessórios</b>",
              ],
            },
          ]}
        />
        <RoundItems
          headerTitle="3 tipos de bicos."
          headerSubtitle="para você fazer uma limpeza completa"
          headerType="tertiary"
          items={[
            {
              image: "/nozzles/nozzle-1.jpg",
              title: "Bico Leque",
              isAlignLeft: true,
              type: "tertiary",
              subtitle: "Jato plano, com alta vazão.",
              description:
                "Versátil e com uma excelente capacidade de limpeza. Podeer posicionado na vertical ou horizontal com um simples ajuste atraés da lança, sem precisar torcer a pistola.",
            },
            {
              image: "/nozzles/nozzle-2.jpg",
              title: "Bico Turbo",
              isAlignLeft: true,
              type: "tertiary",
              subtitle: "Agiliza limpezas mais difíceis.",
              description:
                "Tem o poder de limpeza do jato reto e abranência do leque, possibilitando a remoção de sujeiras difíceis em menor tempo.",
            },
            {
              image: "/nozzles/nozzle-3.jpg",
              title: "Bico Detergente*",
              isAlignLeft: true,
              type: "tertiary",
              subtitle: "Auxilia na remoção de sujeiras.",
              description:
                "Utilizado na aplicação de detergentes, auxilia na limpeza das superfícies. Na máquina há um local próprio para armazenamento desse conjunto. <br><span>*Para os modelos RE 90, RE 95, RE 110</span>",
            },
          ]}
        />
      </div>
      <SquareItems
        tips={tips}
        elementId="tips"
        headerTitle="Confira algumas dicas essenciais sobre lavadoras de alta pressão."
        headerType="primary"
      />
      <WhereToFindeStripe
        type="tertiary"
        title="SE INTERESSOU POR ALGUMA LAVADORA STIHL?"
        subtitle="VEJA AGORA ONDE COMPRAR!"
        buttonText="ENCONTRE UM REVENDEDOR"
        buttonLink="/onde-encontrar"
        buttonSize="md"
      />
    </main>
  );
}

export default Home;
