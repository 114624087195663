import Button from "../../atom/Button";

import styles from "./TipBox.module.scss";

function TipBox({ url, title, resume, images}) {
  return (
    <div className={styles.tipBox}>
      <div className={styles.image}>
        <img alt={title} src={`/tips/${images}`} />
      </div>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}  dangerouslySetInnerHTML={{ __html: resume }} />
      <Button
        to={`/dicas/${url}`}
        theme="secondary"
        size="sm"
        className={styles.button}
      >
        LEIA MAIS
      </Button>
    </div>
  );
}

export default TipBox;
