import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./Button.module.scss";

function Button({
  children,
  to,
  onClick,
  theme,
  type,
  size = "md",
  className,
}) {
  const ButtonOrLink = ({ children, to, onClick, className }) =>
    to ? (
      to.includes("http") ? (
        <a href={to} target="_blank" className={className}>
          {children}
        </a>
      ) : (
        <Link to={to} className={className}>
          {children}
        </Link>
      )
    ) : (
      <button onClick={onClick} className={className}>
        {children}
      </button>
    );
  return (
    <ButtonOrLink
      className={classNames(
        styles.button,
        styles[type],
        styles[theme],
        styles[size],
        className
      )}
      {...(to ? { to } : {})}
      {...(onClick ? { onClick } : {})}
    >
      {children}
    </ButtonOrLink>
  );
}

export default Button;
