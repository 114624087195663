import classNames from "classnames";
import styles from "./FormGroup.module.scss";

function FormGroup({ label, mandatory, children, className }) {
  return (
    <div className={classNames(styles.formGroup, className)}>
      <label
        className={classNames({
          [styles.isMandatory]: mandatory,
        })}
      >
        {label}
      </label>
      {children}
    </div>
  );
}

export default FormGroup;
