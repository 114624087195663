import { useEffect, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import HeaderPage from "../../components/molecule/HeaderPage";
import WhereToFindForm from "../../components/molecule/WhereToFindForm";
import StoresList from "../../components/templates/StoresList";

import {
  getStatesFromStores,
  getCitiesFromStoresAndState,
  getStoresFromStateAndCity,
} from "../../utils/filterStores";

import allStores from "../../data/stores.json";

import styles from "./WhereToFind.module.scss";

function WhereToFindPage() {
  const initialValues = {
    state: "",
    city: "",
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const [form, setForm] = useState(initialValues);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setStates(getStatesFromStores(allStores));
  }, []);

  useEffect(() => {
    setCities(getCitiesFromStoresAndState(allStores, form.state));
  }, [form.state]);

  useEffect(() => {
    setStores(getStoresFromStateAndCity(allStores, form.state, form.city));
  }, [form.city]);

  return (
    <div className={styles.contact}>
      <HeaderPage title="Encontre um revendedor" />
      <WhereToFindForm
        handleChange={handleChange}
        states={states}
        cities={cities}
        form={form}
      />
      <StoresList stores={stores} />
    </div>
  );
}

export default WhereToFindPage;
