import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import styles from "./HeaderLinks.module.scss";

function HeaderLinks() {
  return (
    <div className={styles.headerLinks}>
      <div className={classNames("container", styles.container)}>
        <Link to="/onde-encontrar" className={styles.item}>
          <FontAwesomeIcon icon={faLocationDot} className={styles.icon} />
          <span>ENCONTRE UM REVENDEDOR</span>
        </Link>

        <a href="/#newsletter" className={styles.item}>
          <FontAwesomeIcon icon={faEnvelope} className={styles.icon} />
          <span>RECEBA NOVIDADES E PROMOÇÕES</span>
        </a>
      </div>
    </div>
  );
}

export default HeaderLinks;
