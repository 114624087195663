import { useEffect, useState } from "react";
import classNames from "classnames";

import useAppContext from "../../../providers/App";

import Modal from "../../molecule/Modal";
import Input from "../../atom/Input";
import Button from "../../atom/Button";
import Midias from "../../atom/Midias";

import { sendNewsletter } from "../../../requests/Newsletter";
import {
  validateForm,
  changeErrors,
  updateForm,
} from "../../../utils/handleForm";

import styles from "./Newsletter.module.scss";

function Newsletter({ className }) {
  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'clique',
      'eventLabel': 'cadastrar_receber_conteudo',
      'eventValue': ''
    });
  };

  const { setIsLoaderVisible } = useAppContext();

  const initialValues = {
    name: "",
    email: "",
  };
  const [form, setForm] = useState(initialValues);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkErrors = validateForm(errors, form);
    setErrors(checkErrors.errors);
    if (checkErrors.hasError) return;
    setIsLoaderVisible(true);

    let data = new URLSearchParams();
    data.append('Nome', form.name);
    data.append('Email', form.email);
    data.append('Celular', '000');
    data.append('Lp_origem', 'lavadoras');
    data.append('fromSourceName', 'StandardForm');
    data.append('sp_exp', 'yes');

    fetch('https://www.pages02.net/stihlbrasil/form_leads',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      mode: 'no-cors',
      body: data
    })
      .then((response) => {
        setIsLoaderVisible(false);
        setConfirmationMessage("Você foi cadastrado com sucesso!");
      })
      .catch((error) => {
        setIsLoaderVisible(false);
        setConfirmationMessage("Ocorreu um erro, tente novamente mais tarde");
      });
  };

  const handleCloseConfirmation = () => {
    setConfirmationMessage("");
    setForm(initialValues);
  };

  useEffect(() => {
    const updatedErrors = changeErrors(errors, form);
    setErrors(updatedErrors);
  }, [form]);

  return (
    <div id="newsletter" className={classNames(styles.newsletter, className)}>
      <h3 className={styles.title}>
        Quer receber novidades e promoções em primeira mão?
      </h3>
      <p className={styles.subtitle}>
        Inscreva-se e receba conteúdos EXCLUSIVOS da STIHL
      </p>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.fields}>
          <Input
            className={styles.input}
            type="text"
            name="name"
            value={form.name}
            onChange={(e) => updateForm(e, form, setForm)}
            hasError={errors.name}
            placeholder="Digite seu nome"
          />

          <Input
            className={styles.input}
            type="email"
            name="email"
            value={form.email}
            onChange={(e) => updateForm(e, form, setForm)}
            hasError={errors.email}
            placeholder="Digite seu e-mail"
          />
        </div>

        <Button onClick={handleClick} className={styles.button} type="tertiary">
          CADASTRAR
        </Button>
      </form>
      <Midias className={styles.midias} />
      <Modal isOpen={confirmationMessage} closeModal={handleCloseConfirmation}>
        <div className={styles.confirmationMessage}>{confirmationMessage}</div>
      </Modal>
    </div>
  );
}

export default Newsletter;
