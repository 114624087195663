import { useEffect, useState } from "react";
import classNames from "classnames";

import useAppContext from "../../../providers/App";

import FormGroup from "../../atom/FormGroup";
import Input from "../../atom/Input";
import Select from "../../atom/Select";
import Textarea from "../../atom/Textarea";
import Button from "../../atom/Button";
import Modal from "../../molecule/Modal";

import { sendContact } from "../../../requests/Contact";
import {
  validateForm,
  changeErrors,
  updateForm,
} from "../../../utils/handleForm";
import { getCitiesByState } from "../../../utils/filterStates";

import states from "../../../data/states-cities.json";

import styles from "./Contact.module.scss";

function Contact() {
  const { setIsLoaderVisible } = useAppContext();

  const initialValues = {
    name: "",
    email: "",
    state: "",
    city: "",
    phone: "",
    message: "",
  };

  const [cities, setCities] = useState([]);
  const [form, setForm] = useState(initialValues);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    state: false,
    city: false,
    phone: false,
    message: false,
  });
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkErrors = validateForm(errors, form);
    setErrors(checkErrors.errors);
    if (checkErrors.hasError) return;
    setIsLoaderVisible(true);

    sendContact(form)
      .then((response) => {
        setIsLoaderVisible(false);
        setConfirmationMessage("Sua mensagem foi enviada com sucesso!");
      })
      .catch((error) => {
        setIsLoaderVisible(false);
        setConfirmationMessage(
          "Ocorreu um erro no envio, tente novamente mais tarde"
        );
      });
  };

  const handleCloseConfirmation = () => {
    setConfirmationMessage("");
    setForm(initialValues);
  };

  useEffect(() => {
    const updatedErrors = changeErrors(errors, form);
    setErrors(updatedErrors);
  }, [form]);

  useEffect(() => {
    setCities(getCitiesByState(states, form.state));
  }, [form.state]);

  return (
    <section className={styles.contact}>
      <div className={classNames(styles.container, "container")}>
        <p className={styles.description}>
          DÚVIDAS, RECLAMAÇÕES OU SUGESTÕES? MANDE SUA MENSAGEM OU LIGUE PARA
          <strong> 0800 707 5001</strong>.
        </p>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormGroup label="NOME" className={styles.formGroup} mandatory>
            <Input
              name="name"
              onChange={(e) => updateForm(e, form, setForm)}
              value={form.name}
              hasError={errors.name}
            />
          </FormGroup>
          <FormGroup label="E-MAIL" className={styles.formGroup} mandatory>
            <Input
              name="email"
              onChange={(e) => updateForm(e, form, setForm)}
              value={form.email}
              hasError={errors.email}
            />
          </FormGroup>
          <div className={styles.customFields}>
            <FormGroup
              label="ESTADO"
              size="sm"
              className={styles.formGroup}
              mandatory
            >
              <Select
                onChange={(e) => updateForm(e, form, setForm)}
                value={form.state}
                options={states}
                valueIndex="id"
                nameIndex="name"
                name="state"
                placeholder="Selecione um estado"
                hasError={errors.state}
              />
            </FormGroup>
            <FormGroup
              label="CIDADE"
              size="sm"
              className={styles.formGroup}
              mandatory
            >
              <Select
                onChange={(e) => updateForm(e, form, setForm)}
                value={form.city}
                options={cities}
                name="city"
                placeholder="Selecione uma cidade"
                hasError={errors.city}
              />
            </FormGroup>
          </div>
          <FormGroup label="TELEFONE" className={styles.formGroup} mandatory>
            <Input
              name="phone"
              onChange={(e) => updateForm(e, form, setForm)}
              value={form.phone}
              mask="(99)99999-9999"
              hasError={errors.phone}
            />
          </FormGroup>
          <FormGroup label="MENSAGEM" mandatory>
            <Textarea
              name="message"
              onChange={(e) => updateForm(e, form, setForm)}
              value={form.message}
              hasError={errors.message}
            />
          </FormGroup>

          <div className={styles.smallText}>
            <span>*</span> Campos obrigatórios
          </div>

          <Button type="secondary">ENVIAR</Button>
        </form>
      </div>
      <Modal isOpen={confirmationMessage} closeModal={handleCloseConfirmation}>
        <div className={styles.confirmationMessage}>{confirmationMessage}</div>
      </Modal>
    </section>
  );
}

export default Contact;
