import classNames from "classnames";
import ProductBox from "../../molecule/ProductBox";

import styles from "./ProductsList.module.scss";

function ProductsList({ products = [], classes = {} }) {
  return (
    <section className={classNames(styles.products, classes.section)}>
      <ul className={classNames(styles.list, classes.list)}>
        {products.map((product) => (
          <li
            key={product.id}
            className={classNames(styles.item, classes.item)}
          >
            <ProductBox {...product} />
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ProductsList;
