export const getStatesFromStores = (stores) => {
  return stores.reduce((acc, cur) => {
    if (!acc.includes(cur.state)) {
      acc.push(cur.state);
      return acc
    } else {
      return acc;
    }
  }, []).sort()
}

export const getCitiesFromStoresAndState = (stores, state) => {
  return stores.reduce((acc, cur) => {
    if (!acc.includes(cur.city) && cur.state === state) {
      acc.push(cur.city);
      return acc
    } else {
      return acc;
    }
  }, []).sort()
}

export const getStoresFromStateAndCity = (stores, state, city) => {
  return stores.filter(store => store.state === state && store.city === city).sort()
}