import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderPage from "../../components/molecule/HeaderPage";
import ProductDetail from "../../components/templates/ProductDetail";
import styles from "./Product.module.scss";

import products from "../../data/products.json";
import allAttributes from "../../data/attributes.json";
import allAccessories from "../../data/accessories.json";

import background from "../../assets/images/header-3.jpg";

function Product() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [attributesDetail, setAttributesDetail] = useState([]);
  const [accessoriesDetail, setAccessoriesDetail] = useState([]);

  useEffect(() => {
    const product = products.find((product) => product.id === Number(id));

    setAttributesDetail(
      allAttributes.filter((attribute) =>
        product.attributes.includes(attribute.id)
      )
    );
    setAccessoriesDetail(
      allAccessories.filter((accessory) =>
        product.accessories.includes(accessory.id)
      )
    );

    if (product) {
      setProduct(product);
    } else {
      navigate("/not-found");
    }
  }, [id]);

  return (
    <div className={styles.product}>
      <HeaderPage
        breadcrumb={[
          {
            link: "/",
            label: "Página Inicial",
          },
          {
            link: "/produtos",
            label: "Produtos",
          },
          {
            label: product.title,
            active: true,
          },
        ]}
        title={product.reference}
        background={background}
        theme="light"
        showBackButton
      />
      <ProductDetail
        {...product}
        attributes={attributesDetail}
        accessories={accessoriesDetail}
      />
    </div>
  );
}

export default Product;
