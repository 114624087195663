import classNames from "classnames";
import styles from "./Textarea.module.scss";

function Textarea({
  name,
  value,
  onChange,
  hasError,
  placeholder = "",
  className,
}) {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={classNames(styles.textarea, className, {
        [styles.hasError]: hasError,
      })}
    />
  );
}

export default Textarea;
