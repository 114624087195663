import classNames from "classnames";

import useAppContext from "../../../providers/App";

import Modal from "../Modal";

import styles from "./ModalVideo.module.scss";

function ModalVideo({ className }) {
  const { selectedVideoModal, setSelectedVideoModal } = useAppContext();
  return (
    <Modal
      isOpen={!!selectedVideoModal}
      closeModal={() => setSelectedVideoModal(false)}
      className={styles.modal}
    >
      <div className={classNames(styles.modalVideo, className)}>
        <iframe
          width="100%"
          height="100%"
          src={selectedVideoModal?.url}
          title={selectedVideoModal?.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
}

export default ModalVideo;
