import HeaderPage from "../../components/molecule/HeaderPage";
import AccessoriesList from "../../components/templates/AccessoriesList";

import accessories from "../../data/accessories.json";

import styles from "./Accessories.module.scss";

function Accessories() {
  return (
    <div className={styles.accessories}>
      <HeaderPage title="Acessórios" />
      <AccessoriesList accessories={accessories} />
    </div>
  );
}

export default Accessories;
