import HeaderPage from "../../components/molecule/HeaderPage";
import TipsList from "../../components/templates/TipsList";
import styles from "./Tips.module.scss";

import tips from "../../data/tips.json";

function Tips() {
  return (
    <div className={styles.tips}>
      <HeaderPage title="Dicas" />
      <div className="container">
        <TipsList tips={tips} />
      </div>
    </div>
  );
}

export default Tips;
