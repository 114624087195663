import classNames from "classnames";
import InputMask from "react-input-mask";

import styles from "./Input.module.scss";

function Input({
  name,
  type = "text",
  value,
  onChange,
  hasError,
  placeholder = "",
  mask,
  className,
}) {
  if (mask) {
    return (
      <InputMask
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        mask={mask}
        placeholder={placeholder}
        className={classNames(styles.input, className, {
          [styles.hasError]: hasError,
        })}
        autoComplete="nope"
      />
    );
  }

  return (
    <input
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={classNames(styles.input, className, {
        [styles.hasError]: hasError,
      })}
    />
  );
}

export default Input;
