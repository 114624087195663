import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faAngleRight } from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import styles from "./VideoItem.module.scss";

function VideoItem(props) {
  const handleClick = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'gaEvent',
      'eventCategory': 'CTA',
      'eventAction': 'video_youtube',
      'eventLabel': 'ver_todos_os_vídeos',
      'eventValue': ''
    });
  };
  const { setSelectedVideoModal } = useAppContext();
  const { thumb, title, className = "" } = props;
  return (
    <div
      onClick={() => setSelectedVideoModal(props)}
     
      className={classNames(styles.videoItem, className)}
    >
      <div className={styles.image}  onClick={handleClick}>
        <img alt={title} src={`/videos/${thumb}`} />
        <span className={styles.play}>
          <FontAwesomeIcon icon={faPlay} />
        </span>
      </div>
      <div className={styles.title}>
        <p>{title}</p>
        <FontAwesomeIcon icon={faAngleRight} />
      </div>
    </div>
  );
}

export default VideoItem;
