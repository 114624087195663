import React, { useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

import styles from "./Modal.module.scss";

function Modal(props) {
  const {
    isOpen,
    onCloseModal,
    onOpenModal,
    closeModal,
    children,
    className = "",
  } = props;

  useEffect(() => {
    if (isOpen) {
      onOpenModal?.();
    } else {
      onCloseModal?.();
    }
  }, [isOpen]);

  return isOpen ? (
    <React.Fragment>
      <div onClick={closeModal} className={styles.modalOverlay}></div>
      <div className={classNames(styles.modal, className)}>
        <button onClick={closeModal} className={styles.close}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </React.Fragment>
  ) : (
    ""
  );
}

export default Modal;
