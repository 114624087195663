import classNames from "classnames";

import styles from "./RoundItem.module.scss";

function RoundItem({
  image,
  media = [],
  title,
  subtitle,
  description,
  type = "primary",
  isAlignLeft = false,
  listItems = [],
  className = "",
}) {
  return (
    <div
      className={classNames(styles.roundItem, className, {
        [styles.alignLeft]: isAlignLeft,
        [styles.primary]: type === "primary",
        [styles.secondary]: type === "secondary",
        [styles.tertiary]: type === "tertiary",
      })}
    >
      <div className={styles.image}>
        {(media.length || image) && (
          <video autoPlay loop muted playsInline poster={image}>
            {media.map((media, index) => (
              <source key={`media-${index}`} src={media} />
            ))}
          </video>
        )}
      </div>
      <div className={styles.infos}>
        {title && <strong className={styles.title}>{title}</strong>}
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        {description && (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {listItems.length > 0 && (
          <ul className={styles.listItems}>
            {listItems.map((item, index) => (
              <li
                key={`item-${index}`}
                className={styles.listItem}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default RoundItem;
