import classNames from "classnames";

import styles from "./HeaderRoundItems.module.scss";

function HeaderRoundItems({
  type,
  background,
  backgroundColor,
  title,
  subtitle,
  className = "",
}) {
  return (
    <div
      className={classNames(
        styles.headerRoundItems,
        { [styles.primary]: type === "primary" },
        { [styles.secondary]: type === "secondary" },
        { [styles.tertiary]: type === "tertiary" },
        className
      )}
      style={{
        ...(background ? { backgroundImage: `url(${background})` } : {}),
        ...(backgroundColor ? { backgroundColor } : {}),
      }}
    >
      <div className="container">
        <div className={styles.infos}>
          {title && <h3>{title}</h3>}
          {subtitle && <p>{subtitle}</p>}
        </div>
      </div>
    </div>
  );
}

export default HeaderRoundItems;
