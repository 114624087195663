import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import useAppContext from "../../../providers/App";

import Button from "../../atom/Button";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./Banner.module.scss";

function Banner({ banners = [] }) {
  const { isPhone } = useAppContext();
  const navigate = useNavigate();

  const handleBannerLink = (link) => {
    if (!link) return;
      if(link.substr(0, 5) === 'https')
          window.location.href = link;
      else
        navigate(link);
  };

  const arrow = (clickHandler, hasNext, type = "prev") => {
    if (!hasNext) return;
    return (
      <button
        onClick={clickHandler}
        className={classNames(
          styles.arrows,
          type === "prev" ? styles.arrowLeft : styles.arrowRight
        )}
      >
        <FontAwesomeIcon icon={type === "prev" ? faAngleLeft : faAngleRight} />
      </button>
    );
  };

  return !banners?.length ? (
    ""
  ) : (
    <Carousel
      className={classNames(styles.banner, "banner")}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      renderArrowPrev={(clickHandler, hasPrev) =>
        arrow(clickHandler, hasPrev, "prev")
      }
      renderArrowNext={(clickHandler, hasNext) =>
        arrow(clickHandler, hasNext, "next")
      }
    >
      {banners.map((banner, index) => (
        <div
          key={`banner-${index}`}
          className={styles.item}
          onClick={() => handleBannerLink(banner.bannerLink)}
        >
          <img
            src={
              isPhone && banner.imageMobile ? banner.imageMobile : banner.image
            }
            alt={'Imagem Ilustrativa para Banner'}
          />
          <div
            className={classNames(
              styles.container,
              {
                [styles.isAlignRight]: !!banner.isAlignRight,
              },
              "container"
            )}
          >
            <div className={styles.infos}>
              {banner.title && (
                <h1 dangerouslySetInnerHTML={{ __html: banner.title }} />
              )}
              {banner.buttonText && banner.buttonLink && (
                <Button
                  to={banner.buttonLink}
                  className={styles.button}
                  size={isPhone ? "sm" : "md"}
                >
                  <span className={styles.icon}>
                    <FontAwesomeIcon icon={faLocationDot} />
                  </span>
                  {banner.buttonText}
                </Button>
              )}
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}

export default Banner;
