import { useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import useAppContext from "../../../providers/App";

import MenuButton from "../../atom/MenuButton";
import MenuItem from "../../atom/MenuItem";
import SubMenu from "../../molecule/SubMenu";

import { ReactComponent as Logo } from "../../../assets/svgs/logo2.svg";
import menus from "../../../data/menus.json";

import styles from "./Menu.module.scss";

function Menu() {
  const { isMenuOpen, setIsMenuOpen, selectedMenu, setSelectedMenu, isPhone } =
    useAppContext();

  const handleSelectMenu = (menu) => {
    if (!menu?.submenu?.menus?.length) return;

    setSelectedMenu(selectedMenu?.id === menu?.id ? null : menu);
  };

  const handleMenuButton = () => {
    setSelectedMenu(null);
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={styles.menu}>
      <div className={classNames("container", styles.container)}>
        <Link to="/" className={styles.logo}>
          <Logo className={styles.svg} />
        </Link>

        {isPhone && (
          <MenuButton onClick={handleMenuButton} isMenuOpen={isMenuOpen} />
        )}

        {(!isPhone || (isMenuOpen && !selectedMenu)) && (
          <ul className={styles.nav}>
            {menus.map((menu) => {
              const hasSubmenu = menu?.submenu?.menus?.length > 0;
              return (
                <MenuItem
                  key={`menu-${menu.id}`}
                  title={menu.title}
                  hasSubmenu={hasSubmenu}
                  isActive={menu.id === selectedMenu?.id}
                  {...(hasSubmenu
                    ? { onClick: () => handleSelectMenu(menu) }
                    : {})}
                  {...(!hasSubmenu ? { link: menu.link } : {})}
                />
              );
            })}
          </ul>
        )}
      </div>

      {selectedMenu && (
        <SubMenu
          submenu={selectedMenu.submenu}
          onCloseSubMenu={() => setSelectedMenu(null)}
        />
      )}
    </nav>
  );
}

export default Menu;
